import React, { FC, useState } from 'react';
import { Col, Row, Button, Carousel } from 'antd';
import { navigate } from 'gatsby';
import { ROUTES } from '../../constants/routes';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import { StrapiCourseType, StrapiPurchasedContentType } from '../../types';
import CourseCard from '../CourseCard/CourseCard';

type CardsComponentProps = {
  courses: StrapiCourseType[];
  purchasedCourses: StrapiPurchasedContentType[];
};

const CardsComponent: FC<CardsComponentProps> = ({
  courses,
  purchasedCourses,
}) => {
  const { isTablet, isDesktop } = useMediaQuery();
  const [currentCard, setCurrentCard] = useState(0);

  const purchasedCoursesIds = purchasedCourses
    .map(data => data.courses.map(course => course.id))
    .reduce((accumulator, value) => accumulator.concat(value), []);

  const Cards = courses.map((card: StrapiCourseType) => {
    const cardElement = (
      <CourseCard
        purchased={purchasedCoursesIds.includes(card.id)}
        key={card.id}
        strapi_id={card.strapi_id}
        id={card.id}
        name={card.name}
        image={card.picture?.localFile?.url}
        description={card.description}
        altText={card.altText}
        progress={card.progress}
        slug={card.slug}
        lessons={card.lessons}
        themeColor={card.themeColor}
        isActive={card.isActive}
        price={card.price}
        stripeId={card.stripeId}
      />
    );
    return isDesktop || isTablet ? (
      <Col span={isDesktop ? 8 : 12} key={`col-${card.id}`}>
        {cardElement}
      </Col>
    ) : (
      cardElement
    );
  });

  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {isDesktop || isTablet ? (
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 32 },
            { xs: 8, sm: 16, md: 24, lg: 32 },
          ]}
        >
          {Cards}
        </Row>
      ) : (
        <>
          <Carousel
            {...carouselProps}
            className="carousel-dots"
            afterChange={currentID => setCurrentCard(currentID)}
          >
            {Cards}
          </Carousel>
          <Button
            className="section-btn"
            onClick={() =>
              navigate(`${ROUTES.COURSE}/${courses[currentCard].slug}`)
            }
          >
            Zobacz kurs
          </Button>
        </>
      )}
    </>
  );
};

export default CardsComponent;
