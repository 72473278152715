import { Typography } from 'antd';
import React, { FC } from 'react';
import {
  CourseSectionType,
  StrapiCourseType,
  StrapiPurchasedContentType,
} from '../../types';
import CardsComponent from './CardsComponent';
import './CardsSection.less';
import { SECTION_TITLE } from './sectionInfo';
const { Title } = Typography;

type CardsSectionProps = {
  sectionType: CourseSectionType;
  data: {
    allStrapiCourse: {
      nodes: StrapiCourseType[];
    };
    allStrapiPurchasedContent: {
      nodes: StrapiPurchasedContentType[];
    };
  };
};

const CardsSection: FC<CardsSectionProps> = ({ sectionType, data }) => {
  const courses = data?.allStrapiCourse?.nodes;
  const userId =
    typeof window !== 'undefined' && localStorage?.getItem('userid');

  const purchasedCourses = userId
    ? data?.allStrapiPurchasedContent.nodes.filter(
        course => course.owner.strapi_id === parseInt(userId)
      )
    : [];

  return (
    <>
      <section className="cards-section">
        <div className="cards-section-container">
          <Title className="section-title" level={4}>
            {SECTION_TITLE.get(sectionType)}
          </Title>
          <CardsComponent
            courses={courses}
            purchasedCourses={purchasedCourses}
          />
        </div>
      </section>
    </>
  );
};

export default CardsSection;
