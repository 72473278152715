import React, { useState } from 'react';
import { Carousel, Row, Typography } from 'antd';
import { Link } from 'gatsby';
import { ROUTES } from '../../constants/routes';
import DownloadCard from './DownloadCard';
import collectionImage from '../../assets/images/course/collections-img.svg';
import essaysImage from '../../assets/images/course/essays-img.svg';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import { assertValidSDLExtension } from 'graphql/validation/validate';

const { Title } = Typography;

const collectionsHeader = 'Zbiory zadań i arkusze';
const collectionDescription =
  'Zbiory zadań do każdego kursu wraz z proponowanymi odpowiedziami oraz maturalne arkusze pokazowe';
const essaysHeader = 'Wypracowania';
const essaysDescription =
  'Baza przykładowych wypracowań maturalnych wraz z komentarzem';
const sectionTitle = 'Materiały dodatkowe';

const ExtraMaterialsSection = () => {
  const { isMobile } = useMediaQuery();
  const [currentCard, setCurrentCard] = useState(0);
  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const collectionsCard = (
    <Link to={ROUTES.COLLECTIONS}>
      <DownloadCard
        header={collectionsHeader}
        description={collectionDescription}
        src={collectionImage}
        alt=""
      />
    </Link>
  );

  const essaysCard = (
    <Link to={ROUTES.ESSAYS}>
      <DownloadCard
        header={essaysHeader}
        description={essaysDescription}
        src={essaysImage}
        alt=""
      />
    </Link>
  );

  return (
    <>
      <Title className="section-title" level={3}>
        {sectionTitle}
      </Title>
      {isMobile ? (
        <Carousel
          {...carouselProps}
          className="collection-carousel-dots extra-materials"
          afterChange={currentID => setCurrentCard(currentID)}
        >
          {collectionsCard}
          {essaysCard}
        </Carousel>
      ) : (
        <Row className="course-extras-wrapper">
          {collectionsCard}
          {essaysCard}
        </Row>
      )}
    </>
  );
};

export default ExtraMaterialsSection;
