export type MarketingSectionProps = {
  strapiHomepage: {
    heroSections: {
      slogan: string;
      description: string;
      banner: {
        localFile: {
          url: string;
        };
      };
    }[];
  };
};

export type ProductSectionSingleDataProp = {
  description: {
    data: {
      description: string;
    };
  };
  name: string;
  picture: {
    localFile: {
      url: string;
    };
  };
};

export type SingleCardProp = {
  slogan: string;
  description: string;
  banner: {
    localFile: {
      url: string;
    };
  };
};

export type allStrapiChapterType = {
  allStrapiChapter: {
    nodes: [
      {
        name: string;
        description: string;
        image: { localFile: { url: string } };
        price: number;
      }
    ];
  };
};

export type StrapiCollectionType = {
  title: {
    name: string;
  };
  category: string;
  color: string;
  answersPdf: {
    localFile: {
      url: string;
    };
  };
  exercisesPdf: {
    localFile: {
      url: string;
    };
  };
};

export type allStrapiCollectionType = {
  allStrapiCourse: any;
  nodes: StrapiCollectionType[];
};

export enum CourseSectionType {
  AVAILABLE_COURSES,
  EXTRA_MATERIALS,
}

type StrapiPictureType = {
  localFile: { url: string };
};

export type StrapiCourseType = {
  slug: string;
  id: number;
  strapi_id: number;
  altText: string;
  name: string;
  description: string;
  progress: number | null;
  picture: StrapiPictureType;
  lessons: StrapiLessonType[];
  themeColor: string;
  isActive: boolean;
  price: number;
  stripeId: string;
};

export type StrapiPurchasedContentType = {
  courses: {
    id: number;
  }[];
  owner: {
    strapi_id: number;
  };
};

export type StrapiLessonType = {
  name: string;
  description: string;
  lessonNumber: number;
};

export type allStrapiCourseType = {
  allStrapiCourse: any;
  nodes: StrapiCourseType[];
};

export type StrapiChapter = {
  strapi_id: number;
  name: string;
  stripeId: string;
  id: number;
  slug: string;
  description: string;
  themeColor: string;
  picture: {
    localFile: {
      url: string;
    };
  };
  price: number;
  lessons: {
    lessonNumber: number;
    strapi_id: number;
    name: string;
    test: boolean;
  }[];
};

//TODO Piotr Podolski
export type lessonContentType = {
  attachments: [{ localFile: { url: string } }];
  time: string;
  summary: { data: { summary: string } };
  // LessonVideo: [{ playback_id: string }];
  video: {
    localFile: {
      url: string;
    };
  };
};

export type UserDataQueryProps = {
  userData: {
    me: {
      id: string;
      username: string;
      email: string;
    };
  };
  loadingUserDataQuery: boolean;
};

export type formUpdateUserData = {
  username?: string;
  email?: string;
  password?: string;
};

export type TestPageQuestions = {
  description: string;
  strapi_id: number;
  options: {
    description: string;
    strapi_id: number;
  }[];
  correctAnswers: {
    description: string;
    strapi_id: number;
  }[];
}[];

export interface ShoppingCartProps {
  data: {
    allStrapiProduct: {
      nodes: {
        id: number;
        price: number;
        name: string;
        picture: {
          localFile: {
            url: string;
          };
        };
      }[];
    };
    allStrapiCourse: {
      nodes: {
        name: string;
        id: string;
        stripeId: string;
        price: number;
        picture: {
          localFile: {
            url: string;
          };
        };
      }[];
    };
  };
}
