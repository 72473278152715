import React from 'react';
import { Typography, Image, Button } from 'antd';
import './DownloadCard.less';

const { Title, Text } = Typography;

const DownloadCard = ({ header, description, src, alt }) => {
  return (
    <div className="course-download-card">
      <div className="course-download-card-wrapper">
        <Button>Zobacz więcej</Button>
        <div className="card-left">
          <Title level={4}>{header}</Title>
          <Text>{description}</Text>
        </div>
        <Image src={src} alt={alt} preview={false}></Image>
      </div>
    </div>
  );
};

export default DownloadCard;
