import React, { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Typography } from 'antd';

const { Title, Text } = Typography;

import './SloganSection.less';

const SloganSection: FC = () => {
  return (
    <section className="slogan-section">
      <div className="slogan-text-container">
        <Title level={3} className="slogan-title">
          Zaczynamy!
        </Title>
        <Text>
          Sprawdź kursy odpowiadające zagadnieniom z podstawy programowej
          zarówno w formule 2023 i 2015 oraz materiały dodatkowe, które pozwolą
          Ci sprawdzić praktycznie swoją wiedzę.
        </Text>
        <StaticImage
          className="slogan-banner"
          src="../../assets/images/course/course-banner-img.svg"
          alt="slogan-image"
        />
      </div>
    </section>
  );
};

export default SloganSection;
