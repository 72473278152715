import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { graphql, PageProps } from 'gatsby';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../constants/routes';
import {
  CourseSectionType,
  StrapiCourseType,
  StrapiPurchasedContentType,
} from '../types';
import SloganSection from '../components/SloganSection/SloganSection';
import CardsSection from '../components/CardsSection/CardsSection';
import ExtraMaterialsSection from '../components/CardsSection/ExtraMaterialsSection';
import { ProductsSection } from '../components/ProductsSection/ProductsSection';
import { Layout } from '../components/Layout/Layout';
import './course.less';

type DataProps = {
  allStrapiCourse: {
    nodes: StrapiCourseType[];
  };
  allStrapiPurchasedContent: {
    nodes: StrapiPurchasedContentType[];
  };
};

const Course = ({ data }: PageProps<DataProps>) => {
  return (
    <Layout>
      <section className="course-content-container">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.COURSE}>Kurs</Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <SloganSection />
        <CardsSection
          sectionType={CourseSectionType.AVAILABLE_COURSES}
          data={data}
        />
        <ExtraMaterialsSection />
      </section>

      <ProductsSection isCourseSection={true} />
    </Layout>
  );
};

export const query = graphql`
  query AllCoursesQuery {
    allStrapiCourse {
      nodes {
        isActive
        slug
        price
        strapi_id
        altText
        stripeId
        description
        name
        id
        lessons {
          strapi_id
        }
        progress
        themeColor
        picture {
          localFile {
            url
          }
        }
      }
    }
    allStrapiPurchasedContent {
      nodes {
        courses {
          id
        }
        owner {
          strapi_id
        }
      }
    }
  }
`;

export default Course;
