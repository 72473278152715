import { CourseSectionType } from './../../types/index';

export const SECTION_TITLE = new Map();
export const SECTION_DESCRIPTION = new Map();

SECTION_TITLE.set(CourseSectionType.AVAILABLE_COURSES, 'Dostępne kursy');
SECTION_TITLE.set(CourseSectionType.EXTRA_MATERIALS, 'Dodatkowe materiały');

SECTION_DESCRIPTION.set(
  CourseSectionType.AVAILABLE_COURSES,
  'Dostępne kursy Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
);
SECTION_DESCRIPTION.set(
  CourseSectionType.EXTRA_MATERIALS,
  'Dodatkowe materiały Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor'
);
